import { useState } from "react";
import LendingPoolBorrow from "./LendingPoolBorrow";
import LendingPoolFarm from "./LendingPoolFarm";
import LendingPoolLend from "./LendingPoolLend";

export default function LendingPool() {
  const [tab, setTab] = useState("borrow");

  return (
    <div className="container py-8 mx-auto sm:px-2">
      <div className="p-6 overflow-hidden">
        <div className="flex flex-col items-center self-center justify-self-center">
          <div className="flex flex-shrink-0 flex-col items-center space-y-1.5 text-lg">
            <img
              className="inline-block"
              src="https://www.tarot.to/assets/images/dex/spooky.png"
              width="36"
              height="36"
              alt="Exchange"
            />
            <span className="font-medium text-slate-200">Spooky</span>
          </div>
        </div>

        <div className="mb-2 text-xl text-center text-slate-200">FTM/TAROT</div>

        <div className="mt-2.5 grid grid-cols-4 gap-x-4 gap-y-2.5">
          <div className="flex flex-col items-center col-span-4 mb-2 text-lg">
            <div className="flex items-center justify-center space-x-2">
              <div className="text-slate-400">Deposited</div>
              <img
                className="-mt-0.5 inline-block h-4 w-4"
                src="https://www.tarot.to/assets/images/dex/spooky.png"
                alt="Spooky"
              />
              <div className="text-slate-400">LP</div>
            </div>
            <div title="3,801,073 FTM-TAROT LP">$5,351,165</div>
          </div>
          <div className="flex self-center col-span-4 space-x-4 justify-items-center">
            <div className="flex-grow"></div>
            <div className="h-2.5 flex-grow self-center border-t border-l border-dotted border-slate-500"></div>
            <div className="flex items-center flex-shrink-0 justify-self-center">
              <img
                className="inline-block"
                src="https://www.tarot.to/assets/images/token-icons/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83.png"
                width="40"
                height="40"
                alt="Token A"
              />
              <img
                className="-ml-1.5 inline-block"
                src="https://www.tarot.to/assets/images/token-icons/0xC5e2B037D30a390e62180970B3aa4E91868764cD.png"
                width="40"
                height="40"
                alt="Token B"
              />
              <span className="font-medium text-slate-400"></span>
            </div>
            <div className="h-2.5 flex-grow self-center border-t border-r border-dotted border-slate-500"></div>
            <div className="flex-grow"></div>
          </div>
        </div>
        <div className="grid grid-cols-4 mt-5 gap-x-4 gap-y-3">
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="$4,427,793" className="self-center justify-self-end">
              $4.4M
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Total Supply
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Supply
            </h5>
            <span title="$2,866,351" className="self-center justify-self-start">
              $2.9M
            </span>
          </div>
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="$2,185,863" className="self-center justify-self-end">
              $2.2M
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Total Borrowed
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Borrowed
            </h5>
            <span title="$1,935,155" className="self-center justify-self-start">
              $1.9M
            </span>
          </div>
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="49.37%" className="self-center justify-self-end">
              49.37%
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Utilization
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Utilization
            </h5>
            <span title="67.51%" className="self-center justify-self-start">
              67.51%
            </span>
          </div>
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="2.51%" className="self-center justify-self-end">
              2.51%
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Supply APR
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Supply APR
            </h5>
            <span title="9.94%" className="self-center justify-self-start">
              9.94%
            </span>
          </div>
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="5.64%" className="self-center justify-self-end">
              5.64%
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Borrow APR
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Borrow APR
            </h5>
            <span title="16.36%" className="self-center justify-self-start">
              16.36%
            </span>
          </div>
          <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
            <span title="43.75%" className="self-center justify-self-end">
              43.75%
            </span>
            <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
              Farming APR
            </h5>
            <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
              Farming APR
            </h5>
            <span title="49.42%" className="self-center justify-self-start">
              49.42%
            </span>
          </div>
        </div>
      </div>
      <div className="pb-2 mt-4 lg:px-16">
        <div className="relative flex flex-col min-w-0 rounded-lg bg-slate-800">
          <div className="flex-auto">
            <div className="flex text-lg bg-slate-900">
              <button
                type="button"
                className={`flex-auto border-t-2 py-3 text-center font-medium ${
                  tab === "borrow"
                    ? "border-slate-200 bg-slate-800 text-slate-200"
                    : "border-slate-700 bg-slate-700 text-slate-400 hover:bg-slate-600"
                }`}
                onClick={() => setTab("borrow")}
              >
                Borrow
              </button>
              <button
                type="button"
                className={`flex-auto border-t-2 py-3 text-center font-medium ${
                  tab === "lend"
                    ? "border-slate-200 bg-slate-800 text-slate-200"
                    : "border-slate-700 bg-slate-700 text-slate-400 hover:bg-slate-600"
                }`}
                onClick={() => setTab("lend")}
              >
                Lend
              </button>
              <button
                type="button"
                className={`flex-auto border-t-2 py-3 text-center font-medium ${
                  tab === "farm"
                    ? "border-slate-200 bg-slate-800 text-slate-200"
                    : "border-slate-700 bg-slate-700 text-slate-400 hover:bg-slate-600"
                }`}
                onClick={() => setTab("farm")}
              >
                Farm
              </button>
            </div>

            {tab === "borrow" && <LendingPoolBorrow />}
            {tab === "lend" && <LendingPoolLend />}
            {tab === "farm" && <LendingPoolFarm />}
          </div>
        </div>
      </div>
    </div>
  );
}
