import { useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import logo from "./evolution-logo.svg";
import Home from "./Home";
import LendingPool from "./LendingPool";

export default function App() {
  const [active, setActive] = useState(false);

  return (
    <div className="relative min-h-screen pt-20 font-mono bg-slate-900 text-slate-200">
      <nav className="fixed inset-x-0 top-0 z-10 shadow bg-slate-900 shadow-slate-700">
        <div className="px-4 mx-auto max-w-7xl lg:px-6 xl:px-8">
          <div className="flex justify-between h-20 bg-slate-900">
            <div className="flex">
              <div className="flex items-center flex-shrink-0">
                <NavLink to="/">
                  <img src={logo} alt="Evolution" className="w-12 h-12" />
                </NavLink>
              </div>
              <div className="self-center hidden h-10 lg:ml-6 lg:flex lg:space-x-6">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `inline-flex items-center border-b-2 px-1 pt-1 text-xs font-medium xl:text-sm ${
                      isActive
                        ? "border-yellow-500 text-yellow-500"
                        : "border-transparent text-slate-400 hover:border-yellow-300 hover:text-yellow-300"
                    }`
                  }
                >
                  Markets
                </NavLink>
                <NavLink
                  to="/dashboard"
                  className={({ isActive }) =>
                    `inline-flex items-center border-b-2 px-1 pt-1 text-xs font-medium xl:text-sm ${
                      isActive
                        ? "border-yellow-500 text-yellow-500"
                        : "border-transparent text-slate-400 hover:border-yellow-300 hover:text-yellow-300"
                    }`
                  }
                >
                  Dashboard
                </NavLink>

                <div className="z-50 inline-flex items-center px-1 pt-1 text-xs font-medium cursor-pointer text-slate-400 hover:text-slate-300 lg:text-sm">
                  <div className="absolute top-6">
                    <div className="mt-1.5 flex flex-col items-start justify-start self-start">
                      <button
                        className="focus:outline-none"
                        type="button"
                        aria-haspopup="true"
                        onClick={() => setActive(!active)}
                      >
                        {/* dots-horizontal */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                          />
                        </svg>
                      </button>

                      <div
                        className={`flex flex-col rounded border border-slate-700 bg-slate-800 py-0 ${
                          active ? "flex" : "hidden"
                        }`}
                        role="menu"
                        tabindex="0"
                      >
                        <div className="space-y-1" role="none">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://vote.tarot.to"
                            className="block py-2 pl-3 pr-4 text-sm font-medium border-l-4 border-transparent text-slate-400 hover:bg-slate-700"
                            role="menuitem"
                            tabindex="-1"
                          >
                            Vote
                          </a>
                          <a
                            className="block py-2 pl-3 pr-4 text-sm font-medium border-l-4 border-transparent text-slate-400 hover:bg-slate-700"
                            role="menuitem"
                            tabindex="-1"
                            href="/bounty"
                          >
                            LP Vault Bounties
                          </a>
                          <a
                            className="block py-2 pl-3 pr-4 text-sm font-medium border-l-4 border-transparent text-slate-400 hover:bg-slate-700"
                            role="menuitem"
                            tabindex="-1"
                            href="/claim"
                          >
                            Distribution Claims
                          </a>
                          <a
                            className="block py-2 pl-3 pr-4 text-sm font-medium border-l-4 border-transparent text-slate-400 hover:bg-slate-700"
                            role="menuitem"
                            tabindex="-1"
                            href="/lge"
                          >
                            The Tarot LGE
                          </a>
                          <div
                            className="flex items-center self-center justify-center px-4 pt-2 pb-4 space-x-6"
                            role="menuitem"
                            tabindex="-1"
                          >
                            <a
                              href="https://twitter.com/TarotFinance"
                              className="text-slate-400 hover:text-slate-200"
                              title="Twitter"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="sr-only">Twitter</span>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                aria-hidden="true"
                              >
                                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                              </svg>
                            </a>
                            <a
                              href="https://tarotfinance.medium.com"
                              className="text-slate-400 hover:text-slate-200"
                              title="Medium"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="sr-only">Medium</span>
                              <svg
                                viewBox="0 0 16 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="14"
                                aria-hidden="true"
                              >
                                <path
                                  d="M14.7233 2.15517L16 0.933837V0.666504H11.5773L8.42533 8.51184L4.83933 0.666504H0.202V0.933837L1.69333 2.72917C1.83867 2.86184 1.91467 3.05584 1.89533 3.25117V10.3065C1.94133 10.5605 1.85867 10.8218 1.68 11.0065L0 13.0425V13.3065H4.76333V13.0392L3.08333 11.0065C2.90133 10.8212 2.81533 10.5645 2.852 10.3065V4.20384L7.03333 13.3098H7.51933L11.1147 4.20384V11.4578C11.1147 11.6492 11.1147 11.6885 10.9893 11.8138L9.696 13.0652V13.3332H15.9707V13.0658L14.724 11.8452C14.6147 11.7625 14.558 11.6238 14.5813 11.4892V2.51117C14.558 2.37584 14.614 2.23717 14.7233 2.15517Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </a>
                            <a
                              href="https://docs.tarot.to"
                              className="text-slate-400 hover:text-slate-200"
                              title="GitBook"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="sr-only">GitBook</span>
                              <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                aria-hidden="true"
                              >
                                <path
                                  d="M10.802 17.77a.703.703 0 1 1-.002 1.406a.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406a.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502c.028-.533.212-.947.493-1.107c.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956c.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474c0-.342-.354-.477-.355-.477c-.658-.315-1.669-.788-2.655-1.25c-2.108-.987-4.497-2.105-5.546-2.655c-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397C.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862a2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </a>
                            <a
                              href="https://discord.gg/6ByFHBjqE8"
                              className="text-slate-400 hover:text-slate-200"
                              title="Discord"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="sr-only">Discord</span>
                              <svg
                                viewBox="0 0 18 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="12"
                                aria-hidden="true"
                              >
                                <path
                                  d="M15.7502 1.51245C13.7366 0.0675649 11.8604 0.00749706 11.5019 0L11.2731 0.217142C13.8434 0.935906 15.0867 2.05901 15.0867 2.05901C12.8804 0.993475 10.8013 0.618758 9.00011 0.596221C7.19895 0.61894 5.11981 0.993475 2.91359 2.05901C2.91359 2.05901 4.15683 0.935906 6.72723 0.217142L6.49837 0C6.13991 0.00749706 4.2636 0.0673831 2.25003 1.51245C2.25003 1.51245 0 5.28607 0 9.92071C0.0762429 9.83088 1.39577 11.9123 4.79753 11.9872C4.79753 11.9872 5.36958 11.3508 5.78142 10.7892C3.79833 10.2277 3.0509 9.10462 3.0509 9.10462C5.11254 10.3794 7.0382 10.8699 9.00011 10.8062C10.962 10.8698 12.8877 10.3794 14.9493 9.10462C14.9493 9.10462 14.2019 10.2277 12.2186 10.7892C12.6305 11.3508 13.2025 11.9872 13.2025 11.9872C16.6042 11.9123 17.9238 9.83088 18 9.92071C18.0002 5.28607 15.7502 1.51245 15.7502 1.51245ZM6.23124 8.46819C5.36172 8.46819 4.66004 7.74937 4.66004 6.86586C4.66004 5.98234 5.36172 5.26358 6.23124 5.26358V5.27107C7.09313 5.27107 7.80245 5.98988 7.80245 6.87335C7.80263 7.74938 7.10086 8.46819 6.23142 8.46819H6.23124ZM11.7686 8.46819C10.8991 8.46819 10.1974 7.74938 10.1974 6.87335C10.1974 5.98988 10.9067 5.27107 11.7686 5.27107V5.26358C12.6381 5.26358 13.3398 5.98239 13.3398 6.86586C13.3398 7.74933 12.6383 8.46819 11.7688 8.46819H11.7686Z"
                                  fill="currentColor"
                                ></path>
                              </svg>
                            </a>
                            <a
                              href="https://github.com/tarot-finance"
                              className="text-slate-400 hover:text-slate-200"
                              title="GitHub"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="sr-only">GitHub</span>
                              <svg
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                aria-hidden="true"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden space-x-2 lg:ml-6 lg:flex lg:items-center">
              <div className="z-0 inline-flex rounded-md">
                <button
                  className="inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-center text-white transition-colors border select-none h-9 border-slate-600 bg-slate-800 first:rounded-l last:rounded-r hover:bg-slate-700 focus:outline-none"
                  type="button"
                >
                  Transactions
                </button>
                <a
                  href="/account"
                  className="inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-center text-white transition-colors border select-none h-9 border-slate-600 bg-slate-800 first:rounded-l last:rounded-r hover:bg-slate-700 focus:outline-none"
                >
                  <span className="whitespace-nowrap">0x64b0…f0ed</span>
                </a>
                <button
                  className="inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-center text-white transition-colors border select-none h-9 border-slate-600 bg-slate-800 first:rounded-l last:rounded-r hover:bg-slate-700 focus:outline-none"
                  type="button"
                  title="Disconnect"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <main className="pb-20 mx-auto max-w-7xl">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lending-pool" element={<LendingPool />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </main>
      <footer className="absolute bottom-0 w-full">
        <h2 className="sr-only">Footer</h2>
        <div className="flex justify-center px-4 pb-8 mx-auto sm:px-6 lg:px-8 lg:pb-16">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex items-center space-x-6 md:order-2">
              <a
                href="https://twitter.com/TarotFinance"
                className="text-slate-400 hover:text-slate-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Twitter</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  aria-hidden="true"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
              </a>
              <a
                href="https://tarotfinance.medium.com"
                className="text-slate-400 hover:text-slate-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Medium</span>
                <svg
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="14"
                  aria-hidden="true"
                >
                  <path
                    d="M14.7233 2.15517L16 0.933837V0.666504H11.5773L8.42533 8.51184L4.83933 0.666504H0.202V0.933837L1.69333 2.72917C1.83867 2.86184 1.91467 3.05584 1.89533 3.25117V10.3065C1.94133 10.5605 1.85867 10.8218 1.68 11.0065L0 13.0425V13.3065H4.76333V13.0392L3.08333 11.0065C2.90133 10.8212 2.81533 10.5645 2.852 10.3065V4.20384L7.03333 13.3098H7.51933L11.1147 4.20384V11.4578C11.1147 11.6492 11.1147 11.6885 10.9893 11.8138L9.696 13.0652V13.3332H15.9707V13.0658L14.724 11.8452C14.6147 11.7625 14.558 11.6238 14.5813 11.4892V2.51117C14.558 2.37584 14.614 2.23717 14.7233 2.15517Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a
                href="https://docs.tarot.to"
                className="text-slate-400 hover:text-slate-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">GitBook</span>
                <svg
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  aria-hidden="true"
                >
                  <path
                    d="M10.802 17.77a.703.703 0 1 1-.002 1.406a.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406a.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502c.028-.533.212-.947.493-1.107c.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956c.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474c0-.342-.354-.477-.355-.477c-.658-.315-1.669-.788-2.655-1.25c-2.108-.987-4.497-2.105-5.546-2.655c-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397C.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862a2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a
                href="https://discord.gg/6ByFHBjqE8"
                className="text-slate-400 hover:text-slate-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Discord</span>
                <svg
                  viewBox="0 0 18 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="12"
                  aria-hidden="true"
                >
                  <path
                    d="M15.7502 1.51245C13.7366 0.0675649 11.8604 0.00749706 11.5019 0L11.2731 0.217142C13.8434 0.935906 15.0867 2.05901 15.0867 2.05901C12.8804 0.993475 10.8013 0.618758 9.00011 0.596221C7.19895 0.61894 5.11981 0.993475 2.91359 2.05901C2.91359 2.05901 4.15683 0.935906 6.72723 0.217142L6.49837 0C6.13991 0.00749706 4.2636 0.0673831 2.25003 1.51245C2.25003 1.51245 0 5.28607 0 9.92071C0.0762429 9.83088 1.39577 11.9123 4.79753 11.9872C4.79753 11.9872 5.36958 11.3508 5.78142 10.7892C3.79833 10.2277 3.0509 9.10462 3.0509 9.10462C5.11254 10.3794 7.0382 10.8699 9.00011 10.8062C10.962 10.8698 12.8877 10.3794 14.9493 9.10462C14.9493 9.10462 14.2019 10.2277 12.2186 10.7892C12.6305 11.3508 13.2025 11.9872 13.2025 11.9872C16.6042 11.9123 17.9238 9.83088 18 9.92071C18.0002 5.28607 15.7502 1.51245 15.7502 1.51245ZM6.23124 8.46819C5.36172 8.46819 4.66004 7.74937 4.66004 6.86586C4.66004 5.98234 5.36172 5.26358 6.23124 5.26358V5.27107C7.09313 5.27107 7.80245 5.98988 7.80245 6.87335C7.80263 7.74938 7.10086 8.46819 6.23142 8.46819H6.23124ZM11.7686 8.46819C10.8991 8.46819 10.1974 7.74938 10.1974 6.87335C10.1974 5.98988 10.9067 5.27107 11.7686 5.27107V5.26358C12.6381 5.26358 13.3398 5.98239 13.3398 6.86586C13.3398 7.74933 12.6383 8.46819 11.7688 8.46819H11.7686Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </a>
              <a
                href="https://github.com/tarot-finance"
                className="text-slate-400 hover:text-slate-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">GitHub</span>
                <svg
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
