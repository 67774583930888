import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./evolution-logo.svg";
import OverallStats from "./OverallStats";

export default function Home() {
  const [flip, setFlip] = useState(false);

  return (
    <div className="container py-8 mx-auto sm:px-2">
      <div className="space-y-12">
        <OverallStats />

        {/* Lending pools */}
        <div className="space-y-3 lg:mx-4">
          <div className="flex flex-wrap items-center space-x-4 lg:flex-nowrap">
            <h2 className="flex-grow min-w-full text-2xl font-semibold lg:min-w-0 lg:whitespace-nowrap">
              Lending Pools
            </h2>
            <div className="flex flex-col flex-grow space-x-2 lg:flex-row lg:justify-end">
              {/* Filter */}
              <div className="relative !ml-0 py-3 lg:py-0 lg:pr-4">
                <button
                  className="flex flex-row items-center space-x-2 focus:outline-none"
                  type="button"
                  aria-haspopup="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="whitespace-nowrap">All Types</div>
                </button>
              </div>
              {/* Sort */}
              <div className="relative !ml-0 py-3 lg:py-0 lg:pr-4">
                <button
                  className="flex flex-row items-center space-x-2 focus:outline-none"
                  type="button"
                  aria-haspopup="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                  </svg>
                  <div className="whitespace-nowrap">Default Sort</div>
                </button>
              </div>
            </div>
            <div className="relative flex items-stretch w-full py-2 border rounded-lg flex-nowrap border-slate-400 bg-slate-800 lg:w-1/4">
              <div className="flex self-center mx-2 text-slate-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="flex items-end flex-grow">
                <input
                  className="flex-grow w-0 overflow-visible border-none bg-slate-800 focus:border-transparent focus:outline-none focus:ring-transparent"
                  title="Filter"
                  autoComplete="off"
                  autoCorrect="off"
                  type="text"
                  placeholder="Filter pools"
                  minLength="1"
                  maxLength="79"
                  spellCheck="false"
                  value=""
                  onChange={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 auto-rows-fr gap-x-8 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
            {/* Lending pool card */}
            <div className="relative z-auto w-full h-full">
              {/* Front face */}
              <div
                className={`${
                  flip ? "hidden" : "block"
                } relative h-full w-full`}
              >
                <button
                  type="button"
                  className="absolute top-0 left-0 m-4 border rounded-full shadow-lg border-slate-600 bg-slate-800 text-slate-400 opacity-70 hover:text-slate-200"
                  onClick={() => setFlip(true)}
                >
                  {/* cog */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 m-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <Link to="/lending-pool" className="block">
                  <div className="h-full p-6 overflow-hidden transition-all border duration-350 rounded-xl border-slate-700 bg-slate-800 hover:bg-slate-700">
                    <div className="-mt-0.5 -mb-7 -mr-2 grid grid-cols-4 gap-x-4">
                      <div className="flex justify-end col-span-4">
                        <div className="inline-block p-0 border rounded-full border-slate-200 bg-slate-800 text-slate-200">
                          <div className="flex flex-row items-center flex-nowrap opacity-80">
                            <img
                              src={logo}
                              alt="Evolution"
                              className="inline-block w-6 h-6"
                            />
                            <span className="ml-1.5 mr-2.5">FARM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 mt-8 mb-0 gap-x-4">
                      <div className="flex flex-col items-center self-center col-span-4 justify-self-center">
                        <div className="flex flex-shrink-0 flex-col items-center space-y-1.5 text-lg">
                          <img
                            className="inline-block"
                            src="https://www.tarot.to/assets/images/dex/spooky.png"
                            width="36"
                            height="36"
                            alt="Exchange"
                          />
                          <span className="font-medium text-slate-200">
                            Spooky
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 mb-2 gap-x-4">
                      <span className="items-center self-center col-span-4 text-xl justify-self-center text-slate-200 saturate-50 filter">
                        FTM/TAROT
                      </span>
                    </div>
                    <div className="mt-2.5 grid grid-cols-4 gap-x-4 gap-y-2.5">
                      <div className="flex flex-col items-center col-span-4 mb-2 text-lg">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="text-slate-400">Deposited</div>
                          <img
                            className="-mt-0.5 inline-block h-4 w-4"
                            src="https://www.tarot.to/assets/images/dex/spooky.png"
                            alt="Spooky"
                          />
                          <div className="text-slate-400">LP</div>
                        </div>
                        <div title="3,817,324 FTM-TAROT LP">$5,313,406</div>
                      </div>
                      <div className="flex self-center col-span-4 space-x-4 justify-items-center">
                        <div className="flex-grow"></div>
                        <div className="h-2.5 flex-grow self-center border-t border-l border-dotted border-slate-500"></div>
                        <div className="flex items-center flex-shrink-0 justify-self-center">
                          <img
                            className="inline-block"
                            src="https://www.tarot.to/assets/images/token-icons/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83.png"
                            width="40"
                            height="40"
                            alt="Token A"
                          />
                          <img
                            className="-ml-1.5 inline-block"
                            src="https://www.tarot.to/assets/images/token-icons/0xC5e2B037D30a390e62180970B3aa4E91868764cD.png"
                            width="40"
                            height="40"
                            alt="Token B"
                          />
                          <span className="font-medium text-slate-400"></span>
                        </div>
                        <div className="h-2.5 flex-grow self-center border-t border-r border-dotted border-slate-500"></div>
                        <div className="flex-grow"></div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 mt-5 gap-x-4 gap-y-6">
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="$3,573,701"
                          className="self-center justify-self-end"
                        >
                          $3.6M
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Total Supply
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Supply
                        </h5>
                        <span
                          title="$2,839,865"
                          className="self-center justify-self-start"
                        >
                          $2.8M
                        </span>
                      </div>
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="$2,170,578"
                          className="self-center justify-self-end"
                        >
                          $2.2M
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Total Borrowed
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Borrowed
                        </h5>
                        <span
                          title="$1,926,248"
                          className="self-center justify-self-start"
                        >
                          $1.9M
                        </span>
                      </div>
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="60.74%"
                          className="self-center justify-self-end"
                        >
                          60.74%
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Utilization
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Utilization
                        </h5>
                        <span
                          title="67.83%"
                          className="self-center justify-self-start"
                        >
                          67.83%
                        </span>
                      </div>
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="4.22%"
                          className="self-center justify-self-end"
                        >
                          4.22%
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Supply APR
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Supply APR
                        </h5>
                        <span
                          title="10.43%"
                          className="self-center justify-self-start"
                        >
                          10.43%
                        </span>
                      </div>
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="7.72%"
                          className="self-center justify-self-end"
                        >
                          7.72%
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Borrow APR
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Borrow APR
                        </h5>
                        <span
                          title="17.09%"
                          className="self-center justify-self-start"
                        >
                          17.09%
                        </span>
                      </div>
                      <div className="grid grid-cols-4 col-span-4 gap-x-4 ">
                        <span
                          title="43.66%"
                          className="self-center justify-self-end"
                        >
                          43.66%
                        </span>
                        <h5 className="self-center inline-block col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:hidden xl:inline-block">
                          Farming APR
                        </h5>
                        <h5 className="self-center hidden col-span-2 font-medium text-center text-md justify-self-center text-slate-400 lg:inline-block xl:hidden">
                          Farming APR
                        </h5>
                        <span
                          title="49.20%"
                          className="self-center justify-self-start"
                        >
                          49.20%
                        </span>
                      </div>
                      <div className="flex flex-col justify-between col-span-4 p-2 py-3 mt-3 -mx-2 space-y-3 border rounded-lg border-slate-600 bg-slate-700">
                        <h5 className="flex items-center self-center space-x-1 font-medium text-md justify-self-start text-slate-400">
                          <span className="whitespace-nowrap">
                            Leveraged LP APR
                          </span>
                          <div className="inline-block">
                            {/* question */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5 text-slate-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </div>
                        </h5>
                        <div className="flex flex-row items-center justify-around space-x-6">
                          <div className="flex-grow"></div>
                          <div className="flex flex-col items-center self-center mt-2 space-y-2 text-lg font-semibold justify-self-end text-slate-200">
                            <span className="inline-block p-2 text-sm font-medium leading-none text-center align-baseline rounded-full whitespace-nowrap bg-slate-600 text-slate-200">
                              1x
                            </span>
                            <span className="self-center text-slate-400">
                              1.41%
                            </span>
                          </div>
                          {/* right arrow */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 text-slate-100"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <div className="flex flex-col items-center self-center mt-2 space-y-2 text-lg font-semibold justify-self-end text-slate-200">
                            <span className="inline-block p-2 text-sm font-medium leading-none text-center align-baseline bg-yellow-500 rounded-full whitespace-nowrap text-slate-900">
                              5x
                            </span>
                            <span className="self-center">143%</span>
                          </div>
                          <div className="flex-grow"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* Back face */}
              <div className={`${flip ? "block" : "hidden"} h-full w-full`}>
                <div className="h-full p-4 overflow-hidden border rounded-xl border-slate-600 bg-slate-700">
                  <div className="overflow-hidden">
                    <div className="grid grid-cols-4 gap-x-4">
                      <div className="flex flex-col items-start justify-center col-span-4 xl:flex-row xl:items-center xl:justify-start">
                        <button
                          type="button"
                          className="mt-1.5 inline-block rounded-full border border-slate-200 bg-slate-800 p-0 text-slate-400 hover:text-slate-200"
                          onClick={() => setFlip(false)}
                        >
                          {/* left arrow */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-5 h-5 opacity-80"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <div className="flex self-center justify-end flex-grow mt-4 font-semibold xl:mt-0 xl:self-end">
                          <div className="flex flex-col items-center xl:mt-2 xl:-mb-2 xl:items-end">
                            <div className="text-sm">Tarot Classic</div>
                            <div>Spooky WFTM/TAROT</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex mt-2"></div>
                    <div className="flex flex-col space-y-1.5 p-0 pt-4 text-xs">
                      <div className="mt-4 text-base">Parameters</div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Max Borrow APR</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>500%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Max Supply APR</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>450%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Adjust Speed</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>43.2%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Safety Margin</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>200%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Kink Utilization Rate</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>75%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Liquidation Incentive</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>2%</div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Farming Shares</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div>400/2000</div>
                      </div>
                    </div>
                    <div className="flex flex-col space-y-1.5 p-0 pt-4 text-xs">
                      <div className="mt-4 text-base">Addresses</div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>LendingPool</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0xdffa…4067</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>WFTM Token</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x21be…4c83</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>TAROT Token</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0xc5e2…64cd</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>UniswapV2Pair</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x11d9…d70f</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>Collateral</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x1dc6…0006</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>WFTM Borrowable</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x5dd7…ace9</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>TAROT Borrowable</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0xe0d1…8329</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>WFTM FarmingPool</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x2cca…e6f8</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>TAROT FarmingPool</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x54cb…33db</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>TarotFactory</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x35c0…32ea</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                      <div className="flex space-x-2 justify-items-center">
                        <div>TarotRouter</div>
                        <div className="flex-grow border-b border-dotted opacity-20"></div>
                        <div
                          title="Copy to clipboard"
                          className="flex items-center space-x-2 cursor-default hover:text-slate-400"
                        >
                          <div>0x283e…ad98</div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4 opacity-70"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
                            <path d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="absolute inset-0 flex flex-col items-center justify-end pointer-events-none inset-y-16 transform-gpu opacity-20 mix-blend-soft-light contrast-50 filter">
                      <div className="z-10 mb-2 combined">
                        <img
                          className="absolute inline-block rounded-full opacity-50"
                          src="/assets/images/token-icons/0xC5e2B037D30a390e62180970B3aa4E91868764cD.png"
                          width="64"
                          height="64"
                          alt="TAROT"
                        />
                        <img
                          className="inline-block rounded-full"
                          src="/assets/images/token-icons/0xC5e2B037D30a390e62180970B3aa4E91868764cD.png"
                          width="64"
                          height="64"
                          alt="TAROT"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <button className="h-10 p-10 px-4 py-2 m-20 leading-normal text-center text-white border-none rounded select-none bg-slate-700 hover:bg-slate-600 focus:outline-none disabled:cursor-auto disabled:opacity-50">
              <span>Load More</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
