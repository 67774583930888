import logo from "./evolution-logo.svg";

export default function OverallStats() {
  return (
    <div className="grid grid-cols-1 m-0 overflow-hidden divide-y rounded-lg divide-slate-900 bg-slate-800 md:grid-cols-4 md:divide-y-0 md:divide-x-4 md:rounded lg:m-4">
      <div className="px-4 py-5 sm:p-6 sm:px-2 lg:px-6">
        <dt className="text-sm font-normal text-slate-200 lg:text-lg">
          Total Value Locked
        </dt>
        <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
          <div className="flex items-baseline text-xl font-semibold text-yellow-100 lg:text-2xl">
            $187,737,026
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6 sm:px-2 lg:px-6">
        <dt className="text-sm font-normal text-slate-200 lg:text-lg">
          Total Supplied
        </dt>
        <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
          <div className="flex items-baseline text-xl font-semibold text-yellow-100 lg:text-2xl">
            $146,596,011
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6 sm:px-2 lg:px-6">
        <dt className="text-sm font-normal text-slate-200 lg:text-lg">
          Total Borrowed
        </dt>
        <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
          <div className="flex items-baseline text-xl font-semibold text-yellow-100 lg:text-2xl">
            $96,976,067
          </div>
        </dd>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <dt className="flex items-center space-x-2 font-normal text-slate-200 lg:text-lg">
          <img src={logo} alt="Evolution" className="h-7 w-7" />
          <span>Evolution</span>
        </dt>
        <dd className="flex items-baseline justify-between mt-1 sm:block md:flex lg:flex">
          <div className="flex items-center flex-grow space-x-2 text-xl font-semibold text-yellow-100 md:text-lg lg:text-2xl">
            <div className="flex items-center space-x-2">
              <span>$0.35</span>
            </div>
            <div className="flex items-center pl-1 -mt-1 space-x-2">
              <a
                href="https://spookyswap.finance/swap?inputCurrency=FTM&amp;outputCurrency=0xc5e2b037d30a390e62180970b3aa4e91868764cd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="inline-block w-6 h-6 rounded-full"
                  src="https://www.tarot.to/assets/images/dex/spooky.png"
                  alt="SpookySwap"
                />
              </a>
              <a
                href="https://swap.spiritswap.finance/#/swap/0xC5e2B037D30a390e62180970B3aa4E91868764cD"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="inline-block w-6 h-6 rounded-full"
                  src="https://www.tarot.to/assets/images/dex/spirit.png"
                  alt="SpiritSwap"
                />
              </a>
              <a
                href="https://beets.fi/#/trade/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee/0xc5e2b037d30a390e62180970b3aa4e91868764cd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="inline-block w-6 h-6 rounded-full"
                  src="https://www.tarot.to/assets/images/dex/beethovenx.png"
                  alt="Beethoven X"
                />
              </a>
            </div>
          </div>
        </dd>
      </div>
    </div>
  );
}
