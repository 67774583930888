export default function LendingPoolLend() {
  return (
    <div>
      <div className="flex flex-wrap py-4">
        <div className="w-full px-6 md:w-1/2">
          <div className="mb-4 leading-none">
            <div className="">Supply Balance</div>
            <div className="pt-1 font-semibold">$0</div>
          </div>
        </div>
        <div className="w-full px-6 md:w-1/2">
          <div className="mb-4 leading-none">
            <div className="">Total Supply APR</div>
            <div className="pt-1 font-semibold">$0</div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap py-4 border-t border-slate-700">
        <div className="md:w-1/4">
          <div className="flex flex-wrap items-center h-full">
            <div className="w-full max-w-[90px] grow basis-0 text-center">
              <img
                className="inline-block"
                src="https://www.tarot.to/assets/images/token-icons/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83.png"
                width="32"
                height="32"
                alt="Token"
              />
            </div>
            <div className="max-w-full grow basis-0">
              <div className="flex flex-col self-end">
                <div>FTM</div>
                <div className="flex flex-row items-center space-x-3 text-slate-400">
                  <div className="hover:text-slate-200">
                    <a
                      target="_blank"
                      title="View on FTMScan"
                      href="https://ftmscan.com/address/0xdffae68e2b7fb1f5438df5c771fd8ae73a9b4067"
                      rel="noopener noreferrer"
                    >
                      {/* cube */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                      </svg>
                    </a>
                  </div>
                  <div className="hover:text-slate-200">
                    <a
                      target="_blank"
                      title="Pair Info"
                      href="https://info.spookyswap.finance/pair/0x11d90ea9d16e1ee5879b299a819f6d618816d70f"
                      rel="noopener noreferrer"
                    >
                      {/* chart-bar */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/3 px-4">
          <div className="flex flex-wrap items-center w-full py-2">
            <div className="w-1/2 text-slate-200">Supplied:</div>
            <div className="w-1/2">
              <div>0 FTM</div>
              <div>$0</div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-5/12 px-2 pb-2">
          <div className="flex flex-wrap py-2">
            <div className="w-1/2">
              <button
                type="button"
                className="w-32 text-white rounded h-9 bg-slate-600 disabled:bg-slate-700 disabled:text-slate-500"
              >
                Supply
              </button>
            </div>
            <div className="w-1/2">
              <button
                type="button"
                className="w-32 text-white rounded h-9 bg-slate-600 disabled:bg-slate-700 disabled:text-slate-500"
                disabled
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap py-4 border-t border-slate-700">
        <div className="md:w-1/4">
          <div className="flex flex-wrap items-center h-full -mx-4">
            <div className="max-w-[90px] grow basis-0 text-center">
              <img
                className="inline-block"
                src="https://www.tarot.to/assets/images/token-icons/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83.png"
                width="32"
                height="32"
                alt="Token"
              />
            </div>
            <div className="max-w-full grow basis-0">
              <div className="flex flex-col self-end">
                <div>FTM</div>
                <div className="flex flex-row items-center space-x-3 text-slate-400">
                  <div className="hover:text-slate-200">
                    <a
                      target="_blank"
                      title="View on FTMScan"
                      href="https://ftmscan.com/address/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                      rel="noopener noreferrer"
                    >
                      {/* cube */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                      </svg>
                    </a>
                  </div>
                  <div className="hover:text-slate-200">
                    <a
                      target="_blank"
                      title="Token Info"
                      href="https://info.spookyswap.finance/token/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                      rel="noopener noreferrer"
                    >
                      {/* chart-bar */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-1/3 px-4">
          <div className="flex flex-wrap items-center w-full py-2">
            <div className="w-1/2 text-slate-200">Supplied:</div>
            <div className="w-1/2">
              <div>0 TAROT</div>
              <div>$0</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-5/12 px-2 pb-2">
          <div className="flex flex-wrap py-2">
            <div className="w-1/2">
              <button
                type="button"
                className="w-32 text-white rounded h-9 bg-slate-600 disabled:bg-slate-700 disabled:text-slate-500"
                disabled
              >
                Supply
              </button>
            </div>
            <div className="w-1/2">
              <button
                type="button"
                className="w-32 text-white rounded h-9 bg-slate-600 disabled:bg-slate-700 disabled:text-slate-500"
                disabled
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
