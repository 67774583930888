import OverallStats from "./OverallStats";

export default function Dashboard() {
  return (
    <div className="container py-8 mx-auto sm:px-2">
      <OverallStats />

      <div className="flex flex-col-reverse items-end pb-0 pr-0 space-x-4 -mb-7 p-7 lg:flex-row lg:items-center lg:pr-7">
        <div className="flex justify-end flex-grow mb-3 text-xl text-slate-400 lg:whitespace-nowrap">
          <button
            type="button"
            className="flex items-center mr-2 cursor-pointer"
          >
            <span className="mr-2">Hide dust?</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="9 11 12 14 22 4"></polyline>
              <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
            </svg>
          </button>
        </div>
        <div className="relative flex items-stretch w-4/5 py-2 mb-3 border rounded-lg flex-nowrap border-slate-400 bg-slate-800 lg:w-1/4">
          <div className="flex self-center mx-2 text-slate-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>
          <div className="flex items-end flex-grow">
            <input
              className="flex-grow w-0 overflow-visible border-none bg-slate-800 focus:border-transparent focus:outline-none focus:ring-transparent"
              title="Filter"
              autocomplete="off"
              autocorrect="off"
              type="text"
              placeholder="Filter"
              minlength="1"
              maxlength="79"
              spellcheck="false"
              value=""
              onChange={() => {}}
            />
          </div>
        </div>
      </div>

      <div className="mt-12 space-y-3 lg:mx-4">
        <h2 className="mt-6 text-2xl font-semibold">Borrow Positions</h2>
        <div className="grid grid-cols-1 position-card-list auto-rows-fr gap-x-8 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          <a href="https://www.tarot.to/lending-pool/250/0xc69552bf7cd99aa3faf2bbbd5b4aa5b2857513b5">
            <div className="h-full p-6 overflow-hidden transition-all border duration-350 rounded-xl border-slate-700 bg-slate-800 hover:bg-slate-700">
              <div className="grid grid-cols-4 mt-8 mb-2 gap-x-4">
                <div className="flex flex-col items-center self-center col-span-4 justify-self-center">
                  <div className="flex flex-shrink-0 flex-col items-center space-y-1.5 text-lg">
                    <img
                      className="inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="36"
                      height="36"
                      alt="Vault"
                    />
                    <span className="font-medium text-slate-200">
                      Based Agora
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-4 mb-3 gap-x-4">
                <span className="items-center self-center col-span-4 text-xl justify-self-center text-slate-200 saturate-50 filter">
                  TOMB/BASED
                </span>
              </div>
              <div className="grid grid-cols-4 gap-x-4">
                <h5 className="self-center col-span-4 text-lg font-medium text-md justify-self-center text-slate-400">
                  LP Balance
                </h5>
              </div>
              <div className="grid grid-cols-4 mb-4 gap-x-4">
                <span
                  title="$4,406"
                  className="self-center col-span-4 text-xl justify-self-center"
                >
                  $4.4K
                </span>
              </div>
              <div className="mt-2.5 grid grid-cols-4 gap-x-4 gap-y-2.5">
                <div className="flex self-center col-span-4 space-x-4 justify-items-center">
                  <div className="flex-grow"></div>
                  <div className="h-2.5 flex-grow self-center border-t border-l border-dotted border-slate-500"></div>
                  <div className="flex items-center flex-shrink-0 justify-self-center">
                    <img
                      className="inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="40"
                      height="40"
                      alt="Token A"
                    />
                    <img
                      className="-ml-1.5 inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="40"
                      height="40"
                      alt="Token B"
                    />
                    <span className="font-medium text-slate-400"></span>
                  </div>
                  <div className="h-2.5 flex-grow self-center border-t border-r border-dotted border-slate-500"></div>
                  <div className="flex-grow"></div>
                </div>
              </div>
              <div className="grid grid-cols-4 mt-5 text-lg gap-x-4 gap-y-6">
                <div className="grid grid-cols-4 col-span-4 gap-x-4">
                  <span title="$1,756" className="self-center justify-self-end">
                    $1.8K
                  </span>
                  <h5 className="self-center col-span-2 font-medium text-center text-md justify-self-center text-slate-400">
                    Borrowed
                  </h5>
                  <span
                    title="$1,772"
                    className="self-center justify-self-start"
                  >
                    $1.8K
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-x-4">
                <div className="flex flex-col justify-between col-span-4 p-2 py-3 mt-3 -mx-2 space-y-1 border rounded-lg border-slate-500 bg-slate-700">
                  <h5 className="self-center text-lg font-medium text-md justify-self-start text-slate-400">
                    Net Balance
                  </h5>
                  <div className="flex flex-col items-center self-center mt-2 space-y-2 text-2xl font-semibold justify-self-end text-slate-200">
                    <span title="$878.43" className="self-center">
                      $878.43
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center gap-y-6">
                <div>
                  <div className="flex-row text-center">
                    <div className="flex justify-around text-center">
                      <div className="mb-4 leading-none">
                        <div className="name">Leverage</div>
                        <div className="pt-1 font-semibold">5.02x</div>
                      </div>
                      <div className="mb-4 leading-none">
                        <div className="name">TWAP (TOMB/BASED)</div>
                        <div className="flex flex-row items-center justify-center pt-1 font-semibold cursor-pointer">
                          <div>0.9706 </div>
                          <div className="px-2">
                            {/* switch-horizontal */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-slate-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 leading-none">
                      <div className="name">Liquidation Prices</div>
                      <div className="pt-1 font-semibold">
                        <span className="text-orange-400">0.525</span> -{" "}
                        <span className="text-orange-400">1.825</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center mt-4 text-center">
                    <div className="flex flex-col justify-center text-center">
                      <div className="flex items-center">
                        <span className="whitespace-nowrap">
                          Current APR Estimate
                        </span>
                        {/* question */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 ml-2 text-slate-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div className="font-bold text-green-600">480.18%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>

      <div className="mt-12 space-y-3 lg:mx-4">
        <h2 className="mt-6 text-2xl font-semibold">Supply Positions</h2>
        <div className="grid grid-cols-1 position-card-list auto-rows-fr gap-x-8 gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          <a href="https://www.tarot.to/lending-pool/250/0x7a2edc2041e130d61e18eb93a32bb13c331067a0">
            <div className="h-full p-6 overflow-hidden transition-all border duration-350 rounded-xl border-slate-700 bg-slate-800 hover:bg-slate-700">
              <div className="grid grid-cols-4 mt-8 mb-2 gap-x-4">
                <div className="flex flex-col items-center self-center col-span-4 justify-self-center">
                  <div className="flex flex-shrink-0 flex-col items-center space-y-1.5 text-lg">
                    <img
                      className="inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="36"
                      height="36"
                      alt="Vault"
                    />
                    <span className="font-medium text-slate-200">0xDAO</span>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-4 mb-4 gap-x-4">
                <span className="items-center self-center col-span-4 text-xl justify-self-center text-slate-200 saturate-50 filter">
                  xTAROT/TAROT
                </span>
              </div>
              <div className="mt-2.5 grid grid-cols-4 gap-x-4 gap-y-2.5">
                <div className="flex self-center col-span-4 space-x-4 justify-items-center">
                  <div className="flex-grow"></div>
                  <div className="h-2.5 flex-grow self-center border-t border-l border-dotted border-slate-500"></div>
                  <div className="flex items-center flex-shrink-0 justify-self-center">
                    <img
                      className="inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="40"
                      height="40"
                      alt="Token A"
                    />
                    <img
                      className="-ml-1.5 inline-block"
                      src="https://www.tarot.to/assets/images/dex/spooky.png"
                      width="40"
                      height="40"
                      alt="Token B"
                    />
                    <span className="font-medium text-slate-400"></span>
                  </div>
                  <div className="border-tarotBlackHaze-50 h-2.5 flex-grow self-center border-t border-r border-dotted"></div>
                  <div className="flex-grow"></div>
                </div>
              </div>
              <div className="grid grid-cols-4 mt-5 text-lg gap-x-4 gap-y-6">
                <div className="grid grid-cols-4 col-span-4 gap-x-4">
                  <span title="0" className="self-center justify-self-end">
                    0
                  </span>
                  <h5 className="self-center col-span-2 font-medium text-center text-md justify-self-center text-slate-400">
                    Amount
                  </h5>
                  <span
                    title="1173.26"
                    className="self-center justify-self-start"
                  >
                    1.2K
                  </span>
                </div>
                <div className="grid grid-cols-4 col-span-4 gap-x-4">
                  <span title="$0" className="self-center justify-self-end">
                    $0
                  </span>
                  <h5 className="self-center col-span-2 font-medium text-center text-md justify-self-center text-slate-400">
                    Value
                  </h5>
                  <span
                    title="$351.60"
                    className="self-center justify-self-start"
                  >
                    $351.60
                  </span>
                </div>
                <div className="flex flex-col justify-between col-span-4 p-2 py-3 mt-3 -mx-2 space-y-1 border rounded-lg border-slate-500 bg-slate-700">
                  <h5 className="self-center text-lg font-medium text-md justify-self-start text-slate-400">
                    Total Supplied Value
                  </h5>
                  <div className="flex flex-col items-center self-center mt-2 space-y-2 text-2xl font-semibold justify-self-end text-slate-200">
                    <span title="$351.60" className="self-center">
                      $351.60
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center gap-y-6">
                <div>
                  <div className="flex flex-row justify-center mt-4 text-center">
                    <div className="flex flex-col justify-center text-center">
                      <div className="flex items-center">
                        <span className="whitespace-nowrap">
                          Total Supply APR
                        </span>
                        {/* question */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 ml-2 text-slate-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                      <div className="font-bold text-green-600">62.72%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
